import React from 'react'
import GoogleMapReact from 'google-map-react'
import './Map.css'

//const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const API_KEY = 'AIzaSyCudstdgbc9qfAYDe01kdi-LLavV3HahCo'

export default function Map ({ location, zoomLevel }){
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
    position: { lat: location.lat, lng: location.lng },
    map,
    title: 'Zuwenbina Sdn. Bhd.'
    });
    return marker;
  };
  return(
    <div className="map">
      <h2 className="map-h2">Come Visit Us At</h2>
        <div className="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
          >
          </GoogleMapReact>
        </div>
    </div>
  );
}
