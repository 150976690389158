 /* eslint-disable */ 
import React, { Component } from "react";
import './Register.css';
import { Textbox, Checkbox, Textarea } from 'react-inputs-validation';
import "react-inputs-validation/lib/react-inputs-validation.min.css";

function sendEmail(e) {
  const cloudFunc = process.env.REACT_APP_SENDGRID_CLOUDFUNC;
  const msg = {
    name: e.target.form[0].defaultValue,
    phone: e.target.form[1].defaultValue,
    email: e.target.form[2].defaultValue, 
    subject: e.target.form[3].defaultValue,
    text: e.target.form[4].defaultValue,
  }
  fetch(`${cloudFunc}?subject=${msg.subject}&text=${msg.text}&name=${msg.name}&email=${msg.email}&phone=${msg.phone}`)
    .catch(err => console.error(err))
    e.target.form.reset()
}

class Register extends Component {
        constructor(props) {
          super(props);
          this.state = {
            name:"",
            phone: "",
            email: "",
            subject:"",
            description: "",
            agreement: false,
            isAgreementChecked: false,
            hasNameError: true,
            hasDescriptionError: true,
            hasPhoneError: true,
            hasEmailError: true,
            hasSubjectError: true,
            hasAgreementError: true,
            validate:false
          };
          this.validateForm = this.validateForm.bind(this);
        }

        toggleValidating(validate) {
          this.setState({ validate });
        }
      
        validateForm(e) {
          e.preventDefault();
          this.toggleValidating(true);
          const {
            hasNameError,
            hasDescriptionError,
            hasPhoneError,
            hasEmailError,
            hasSubjectError,
            hasAgreementError
          } = this.state;
          if (
            !hasNameError &&
            !hasDescriptionError &&
            !hasPhoneError &&
            !hasEmailError &&
            !hasSubjectError &&
            !hasAgreementError
          ) {
            alert("We have received your inquires. We will get back to you soon!");
            sendEmail(e);
          } else{
            alert("Please fill in the missing information")
          }
        }

        render() {
          const { name,phone,email,subject,description,agreement,isAgreementChecked,validate } = this.state;
          var link = <a href='./PrivacyPolicy'>Privacy Policy</a>;
          return (
              <>
              <body className='body-image'>
              <form  className='my-form'>
              <div className="container">
              <body></body> 
                <div style={{ position: "relative" }}>
                    <label>Name</label>
                    <Textbox
                        attributesWrapper={{}}
                        attributesInput={{
                        id: "Name",
                        name: "Name",
                        type: "text",
                        placeholder:"Please key in your name here"
                        }}
                        value={name} // Optional.[String].Default: "".
                        disabled={false} // Optional.[Bool].Default: false.
                        validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                        validationCallback={res =>
                        this.setState({ hasNameError: res, validate: false })
                        }
                        onBlur={() => {}}
                        validationOption={{
                        name: "Name", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                        check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true
                        }}
                    />
                    <p><br/></p>
                    <label>Phone</label>
                    <Textbox
                      attributesInput={{
                        id: "Phone",
                        name: "Phone",
                        type: "text",
                        placeholder:'Mobile number'
                      }}
                      value={phone} //Optional.[String].Default: "".
                      onChange={(phone, e) => {
                        this.setState({ phone });
                        console.log(e);
                      }} //Required.[Func].Default: () => {}. Will return the value.
                      validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                      validationCallback={res =>
                        this.setState({ hasPhoneError: res, validate: false })
                      }
                      onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                      validationOption={{
                        name: "Phone", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                      }}
                    />
                    <p><br/></p>
                    <label>Email</label>
                    <Textbox
                      attributesInput={{
                        id: "Email",
                        name: "Email",
                        type: "text",
                        placeholder:'Email address'
                      }}
                      value={email} //Optional.[String].Default: "".
                      onChange={(email, e) => {
                        this.setState({ email });
                        console.log(e);
                      }} //Required.[Func].Default: () => {}. Will return the value.
                      validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                      validationCallback={res =>
                        this.setState({ hasEmailError: res, validate: false })
                      }
                      onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                      validationOption={{
                        name: "Email", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                        customFunc: email => {
                          const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                          if (reg.test(String(email).toLowerCase())) {
                            return true;
                          } else {
                            return "is not a valid email address";
                          }
                        }
                      }}
                    />
                      <p><br/></p>
                      <label>Subject</label>
                      <Textbox
                          attributesWrapper={{}}
                          attributesInput={{
                            id: "Subject",
                            name: "Subject",
                            type: "text",
                            placeholder:'Subject'
                          }}
                          value={subject} // Optional.[String].Default: "".
                          disabled={false} // Optional.[Bool].Default: false.
                          validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                          validationCallback={res =>
                          this.setState({ hasSubjectError: res, validate: false })
                          }
                          onBlur={() => {}}
                          validationOption={{
                          name: "Subject", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                          check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                          required: true
                          }}
                      />
                      <p><br/></p>
                      <label>Message</label>
                      <Textarea
                          attributesInput={{ // Optional.
                            id: 'Description',
                            name: 'Description',
                            type: 'text',
                            placeholder: 'Let us know how we can help you'
                          }}
                          value={description} // Optional.[String].Default: "".
                          onChange={(description, e) => {
                              this.setState({ description });
                              console.log(e);
                          }} // Required.[Func].Default: () => {}. Will return the value.
                          validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                          validationCallback={res =>
                          this.setState({
                            hasDescriptionError: res,
                            validate: false
                          })
                        }
                        onBlur={(e) => {console.log(e)}} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: 'Message', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                            required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                        }}
                      />
                      <p><br/></p>
                      <Checkbox
                        attributesInput={{ // Optional.
                            id: 'agreement',
                            name: 'agreement',
                        }}
                        value={agreement} // Required.[String].Default: "".
                        validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                        validationCallback={res =>
                        this.setState({ hasAgreementError: res, validate: false })
                        }
                        onBlur={() => {}} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        checked={isAgreementChecked} // Required.[Bool].Default: false.
                        onChange={(isAgreementChecked, e) => {
                            this.setState({ isAgreementChecked });
                            console.log(e);
                        }} // Required.[Func].Default: () => {}. Will return the value.
                        labelHtml={
                            <div style={{ color: '#4a4a4a', marginTop: '-15px', marginLeft: '25px', fontFamily:'Roboto' ,fontSize :"12px"}}>
                            I have read and agreed with the Terms and Conditions, {link}.
                            </div>
                        } // Required.[Html].Default: none.
                        validationOption={{
                            name: 'Terms and Conditions', // Optional.[String].Default: "". To display in the Error message. i.e Please check the .
                            check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                            required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                        }}
                      />
                      <p><br/></p>
                      <div className="button-center"  >
                          <input type="submit" className="send-message" value="Send Message" onClick={this.validateForm}></input>
                      </div>
                      <p><br/></p>
                </div>         
              </div>
              </form>
              </body>
            </>
          );
        }
}
export default Register