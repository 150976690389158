import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import '../react-awesome-slider.css';
import '../Header.css';
import '../new-project.css';
import './ProjectPage.css'
import Footer from '../Footer'


const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function SpectrumResidence() {
  return (
            <>
              <h1 className="header-project">Spectrum Residence</h1>

              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
            >
              <div data-src="/spectrum_residence/sr_building_sd_min.jpg" />
              <div data-src="/spectrum_residence/sr_dining_sd_min.jpg" />
              <div data-src="/spectrum_residence/sr_living_sd_min.jpg" />
              <div data-src="/spectrum_residence/sr_nightview_sd_min.jpg" />
            </AutoplaySlider>
 
            <div className="text-block-project">
              <p>
              Spectrum Residence is set to alter the skyline of Kota Permai. A combination of style and elegance, Spectrum Residence comprises 2 blocks of 17-storey, low density condominiums. Each block consists of 72 units respectively. 
              A little gem carefully tucked away in Bukit Mertajam, residents will not only relish in breathtaking views of the town, but will also be privileged to enjoy special facilities such as the swimming pool, gymnasium, community hall, children’s playground, BBQ area, yoga room and reading room – just to name a few.  
              Spectrum Residence gives a whole new meaning to accessible public amenities. Situated in the heart of Bukit Mertajam, you will find yourself enjoying life with family and friends has never been easier. Within minutes of Spectrum Residence, you would be able to find public facilities such as the MPSP Sports & Recreational Stadium, numerous banks, schools, mini post office, local eateries, wet markets and an array of shopping malls. Whether you are out for a business meeting, enjoying yourself with family members or socializing with friends, you will always be able to find the perfect location.
              As a bonus, Spectrum Residence is set in near proximity to Juru, Autocity and it takes less than 10 minutes drive to Penang Bridge via Jalan Song Ban Kheng.
              </p>
            </div>
            <Footer/>
          </>
  );
}
