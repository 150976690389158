import React from 'react'
import { InlineIcon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker-radius-outline'
import phoneIcon from '@iconify/icons-mdi/phone-outline'
//import emailIcon from '@iconify/icons-mdi/email-multiple-outline'
import './ContactInfo.css'

const contactDetails = [
  {
    value: '11, Medan Permai Jaya, Taman Permai Jaya, 14000 Bukit Mertajam, Pulau Pinang, Malaysia.',
    icon: locationIcon,
  },
  { value: '+604 5383388', icon: phoneIcon },

]

const renderContactDetails = () =>
  contactDetails.map(detail => (
    <p key={detail.value} className="info-detail">
      <InlineIcon icon={detail.icon} /> {detail.value}
    </p>
  ))

const ContactInfo = () => (
  <section className="info">
    <div>{renderContactDetails()}</div>
  </section>
)

export default ContactInfo

//  { value: 'sales@zuwenbina.com.my', icon: emailIcon },
