import React from 'react';
import './ProjectPage.css'
import Footer from '../Footer';

export default function PrivacyPolicy() {
  return (
    <>
        <h1 className="header-project">Privacy Policy</h1>
        <div className="text-block-project">
            <p>This Privacy Policy is formulated to comply with the requirements of the Personal Data Protection Act 2010 (“Act”). For the purpose of this Privacy Policy, “personal data” shall have the meaning as ascribed to it in the Act, the term Zuwen Bina Sdn Bhd (200301030665)(633085-W) (collectively referred to herein as “ZBSB”, “we,” “us,” or “our”) and the term “you” refers to all customers, visitors and users of this website.</p>
            <p><br/></p>
            <p>This Privacy Policy sets out for ZBSB uses and protects any personal data that you provide to us electronically or otherwise either via this Website or directly to ZBSB.</p>
            <p><br/></p>
            <p>As a data user, we wish to assure you that we respect your privacy and are committed to maintaining, observing and safeguarding the confidentiality of your personal data. This Privacy Policy is not intended to, nor does it create any contractual rights whatsoever or any other legal rights, not does it create any obligation on ZBSB in respect of any other party or on behalf of any party.</p>
            <p><br/></p>
            <p>ZBSB reserves the right to amend this Privacy Policy from time to time without prior notification to reflect changes in the applicable law, our business or Website. You should therefore review this Privacy Policy periodically to ensure you are familiar with its terms. In the event material changes are made to the Privacy Policy we will notify you by placing a prominent notice on the Website.</p>
            <p><br/></p>
            <p>1.1 Personal Data which ZBSB collects includes:-</p>
            <ul className="bullets">
              <li>Any information collected or obtained such as your name, email address, phone number, home address, date-of-birth, etc and such personal information provided to ZBSB;</li>
              <li>Any information collected or obtained such as when you register online via this Website, which may include personal data such as your name and contact details;</li>
              <li>Personal information collected when you submit your resume or application to us for consideration of employment;</li>
              <li>The content of electronic forms you submit via email requesting us to provide services or information, or to take any actions;</li>
              <li>Information submitted if you participate in our survey or competition whether via online or otherwise; and</li>
              <li>Any messages or comments you submit via this Website, which may include personal data such as name, email address and telephone number.</li>
              <li>Agreement for sale and purchase or sub-sale of our properties or for our services;</li>
              <li>Our subsidiaries, associates, and/or members within our group of companies booking forms, registration or any other forms whether in electronic or hardcopy format</li>
            </ul>
            <p><br/></p>
            <p>2.1 By providing any of your personal data to ZBSB whether online or otherwise, you acknowledge that you have read, understood and agree to our Privacy Policy and you consent to the collection, retention and use of your personal data by us for any of the following purposes:</p>
            <ul className="bullets">
              <li>Fulfilling your instructions and providing you with services under any of ZBSB business;</li>
              <li>Processing your application to purchase our products under any of ZBSB’s property development;</li>
              <li>Marketing of products and services and sending any advertising and/or promotional material to you via telephone calls, emails or short message services if you have checked the consent box in the Leasing Enquiry Form, Event / Promotion / Advertising Enquiry Form, Rent Enquiry Form, Sub Sale Enquiry Form, ID Package Enquiry Form, Property Registration and Enquiry Form and Career Form on this Website;</li>
              <li>Conducting research on planning, product, goods, services, security and testing for any ZBSB; notifying you of special offers, current and future products that we consider might be of interest to you and for similar updates and promotion reasons via our Newsletter;</li>
              <li>Performing statistical and analysis of your behaviour and characteristics in order to measure interest in and use of the various sections of this Website;</li>
              <li>Purposes connected with the operation, administration, development or enhancement of ZBSB’s business or this Website;</li>
              <li>Preparing and executing all necessary documents and agreements with you such as in relation sale and purchase transaction, and to carry out the obligations under the agreement, for our products, services and/ or properties;</li>
              <li>Collection of outstanding payments;</li>
              <li>Vacant possession, property/estate management, customer care and/or defect rectification works; and/or</li>
              <li>Post vacant possession services, such as club house services and security services.</li>
              <li>where required by law, where we consider that such use or disclosure is necessary to respond to any claims or legal process, or where ZBSB suspects that fraud or unlawful activity has been, is being or may be engaged in;</li>
              <li>making such disclosures as may be required for any of the above purposes or by law;</li>
              <li>where you have provided your resume, considering you for any jobs that may arise in ZBSB, and if applicable your terms and conditions of employment; and carrying out studies to monitor ZBSB’s recruitment initiatives.</li>
            </ul>
            <p><br/></p>
            <p>2.2 Please note that in the event that sufficient personal data is not supplied, or is not satisfactory to ZBSB then your application or request to ZBSB for any of the above purposes under 2.1 may not be accepted or acted upon.</p>
            <p><br/></p>
            <p>3.1 You further consent that personal data may be disclosed and transferred within the ZBSB which may include transfer to other parts of the ZBSB in different jurisdictions. Other than to those individuals and entities listed below, your personal data will not be revealed by ZBSB to any external body, unless we have your permission, or in some cases, to disclose information to governmental or other regulatory authorities for the prevention or detection of crime, or for the purpose of investigations or for compliance with any other law or an order of court. In that event, we may be precluded from seeking your consent to notify and/or from informing you that notification has been made.</p>
            <p><br/></p>
            <p>3.2 The personal data provided by you may be disclosed by Zuwen Bina Sdn Bhd (633085-W) (200301030665) to:</p>
            <ul className="bullets">
                <li>Our subsidiaries, associates, affiliates, joint venture and/or business partners and/or member within our group of companies </li>
                <li>ZBSB’s Agents and Contractors acting on our behalf</li>
                <li>Any relevant financial institutions</li>
                <li>Any referee whose details are provided by you, to verify or obtain additional information including education institutions, current/previous employers</li>
                <li>Credit Reference Agencies</li>
                <li>The relevant landowner, Joint Management Body and/or management corporation</li>
                <li>Local authorities, Land Office, Kementerian Perumahan dan Kerajaan Tempatan, Tenaga Nasional Berhad, Lembaga Hasil Dalam Negeri, Bahagian Pinjaman Perumahan Perbendaraan, MBSP, MBPP and Bahagian Perumahan Negeri.</li>
                <li>Third Party Service Providers (including management of this Website), for ZBSB operational, administration and development requirements</li>
                <li>Auditors, lawyers, professional advisory, debt collectors, bankers, advertising companies, recruitment agencies, researchers, utility and security services to use, disclose, hold, process, retain or transfer such personal data for the purposes of (2.1) above for and on behalf of ZBSB.</li>
            </ul>
            <p><br/></p>
            <p>3.3 Each of the above parties, other than Regulators and your referees, are similarly bound by a duty of confidentiality to ZBSB in respect of all information disclosed to them.</p>
            <p><br/></p>
            <p>3.4 ZBSB uses and discloses aggregated non-personally identifying information collected through this Website as part of ZBSB’s process of constantly improving this Website and/or ZBSB’s business.</p>
            <p><br/></p>
            <p>4.1 We have put in place appropriate physical, electronic, and administrative procedures to safeguard and help prevent unauthorised access, disclosure, loss, misuse and to maintain data integrity.</p>
            <p><br/></p>
            <p>4.2 All reasonable precautions are taken to secure your personal data but we cannot guarantee that your information will never be disclosed in a manner inconsistent with this Privacy Policy due to unauthorized or illegal acts of third parties in violation of existing laws or the policies of ZBSB.</p>
            <p><br/></p>
            <p>5.1 Your personal data shall be stored either in hard copies in ZBSB offices or stored in servers located in Malaysia and operated by ZBSB) or its service providers.</p>
            <p><br/></p>
            <p>5.2 Any personal data supplied by you will be retained by ZBSB as long as necessary for the fulfilment of the purposes stated in (2.1) above or is required to satisfy legal regulatory, accounting requirements or to protect ZBSB interests. As a general rule the maximum retention period is 7 years.</p>
            <p><br/></p>
            <p>5.3 ZBSB does not offer any online facilities for you to delete your personal data held by ZBSB.</p>
            <p><br/></p>
            <p>5.4 ZBSB take reasonable steps to protect your personal data from misuse and loss and from unauthorised access, modification or disclosure.</p>
            <p><br/></p>
            <p>6.1 Through your use of telecommunications services to access this Website, your communications data (e.g. Internet protocol address) or utilization data (e.g. information on the beginning, end and extent of each access, and information on the telecommunications services you accessed) are technically generated and could conceivably relate to personal data.</p>
            <p><br/></p>
            <p>6.2 To the extent that there is a compelling necessity, the collection, processing and use of your communications or utilization data will occur and will be performed in accordance with the applicable data privacy protection legal framework.</p>
            <p><br/></p>
            <p>7.1 Personal Data held by ZBSB will be kept confidential in accordance with this Privacy Policy pursuant to the Act.</p>
            <p><br/></p>
            <p>7.2 Any questions, comments, suggestions or information other than personal data sent or posted to this Website, or any part of the this Website by visitors will be deemed voluntarily provided to ZBSB on a non-confidential and non-proprietary basis.</p>
            <p><br/></p>
            <p>7.3 ZBSB reserves the right to use, reproduce, disclose, transmit, publish, broadcast and/or post elsewhere such information freely as set out in (3.2) above.</p>
            <p><br/></p>
            <p>8.1 Under the Act, you have the right of access to your personal data held by ZBSB and to request correction of the personal data that is inaccurate, incomplete, misleading or not up-to-date.</p>
            <p><br/></p>
            <p>8.2 If you have any questions regarding this Privacy Policy or if you wish to request access to your personal data or if you wish to correct your personal data or if you wish to withdraw your consent to the ZBSB for the processing of your personal data for the purposes as set out in (2.1) above or for the transfer of your personal data to the parties stated in (3.2) above, you may send your request in writing to the following address:</p>
            <p><br/></p>
            <p>ZUWEN BINA SDN BHD</p>
            <p>11, Medan Permai Jaya,</p>
            <p>Taman Permai Jaya,</p>
            <p>14000 Bukit Mertajam, Pulau Pinang.</p>
        </div>

        <Footer/>
    </>
  );
}