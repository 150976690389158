import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import '../react-awesome-slider.css';
import '../Header.css';
import '../new-project.css';
import './ProjectPage.css'
import Footer from '../Footer'


const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function SpectrumGarden() {
  return (
            <>
              <h1 className="header-project">Spectrum Garden</h1>

              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
            >
              <div data-src="/spectrum_garden/sg_clubhouse_sd_min.jpg" />
              <div data-src="/spectrum_garden/sg_terrace_sd_min.jpg" />
              <div data-src="/spectrum_garden/sg_id_living_sd_min.jpg" />
              <div data-src="/spectrum_garden/sg_id_dining_sd_min.jpg" />
              <div data-src="/spectrum_garden/sg_id_relax_sd_min.jpg" />
              <div data-src="/spectrum_garden/sg_bedroom_sd_min.jpg" />
            </AutoplaySlider>
 
            <div className="text-block-project">
              <p>
              Spectrum Garden is a gated sanctuary which comprises a total of 124 units of triple storey link houses and is specially crafted for your personal comfort. They also come with ceilings spanning from 13ft – 14ft in order to provide your home with a roomy interior and also ample natural lighting. It also serves to promote air circulation in the house. Each unit is impressive to behold and yet comfortable to live in.
              </p>
              <p><br/></p>
              <p>
              As a gated community, residents will get to enjoy special features which include 24-hour security services and also a clubhouse that comes complete with a swimming pool and a gymnasium.  If you are more of an outdoor person, fret not. The landscape park and the jogging trail are at your disposal. Enjoy a leisurely stroll around the charming landscape park or you can always head on to the jogging trail if you are feeling a little more adventurous.
              </p>
              <p><br/></p>
              <p>
              Nestled in a peaceful little town that is Bukit Mertajam, Spectrum Garden is conveniently located along Jalan Song Ban Kheng and is just a short distance away from the well-established Kota Permai. You would be pleased to know that this residency is situated closely to innumerable public amenities such as a the MPSP Sports and Recreational Stadium, various banks, schools, mini post office, wet markets and local eateries.
              </p>
              <p><br/></p>
              <p>
              A selection of shopping malls and supermarkets such as AEON Jusco Seberang Prai, AEON Big, Billion, as well as TESCO and AEON Jusco at Jalan Rozhan are all easily accessible to you. What’s more, Spectrum Garden is also set in near proximity to Autocity and it takes less than 10 minutes drive to Penang Bridge via Jalan Song Ban Kheng.
              </p>
            </div>
            <Footer/>
          </>
  );
}
