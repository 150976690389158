 /* eslint-disable */ 
 import React from 'react';
 import { Link } from 'react-router-dom';
 
 function ProjectCards(props) {
   return (
     <>
       <li className='cards__item'>
         <Link className='cards__item__link_p' to={props.path}>
           <figure className='cards__item__pic-wrap_p' data-category={props.label}>
             <img
               className='cards__item__img'
               alt='House Image'
               src={props.src}
             />
           </figure>
           <div className='cards__item__info_p'>
             <h5 className='cards__item__text'>{props.text}</h5>
           </div>
         </Link>
       </li>
     </>
   );
 }
 
 export default ProjectCards;