import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Company from './components/pages/Company';
import Projects from './components/pages/Projects';
import ContactUs from './components/pages/ContactUs';
import Register from './components/pages/Register';
import SpectrumGarden from './components/pages/SpectrumGarden';
import SpectrumResidence from './components/pages/SpectrumResidence';
import PrivacyPolicy from './components/pages/PrivacyPolicy';

function App() {
  return (
    <>
      <Router>
        <div>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/company' exact component={Company} />
          <Route path='/projects' exact component={Projects} />
          <Route path='/spectrumgarden' exact component={SpectrumGarden} />
          <Route path='/spectrumresidence' exact component={SpectrumResidence} />
          <Route path='/contactus' exact component={ContactUs} />
          <Route path='/register' exact component={Register} />
          <Route path='/privacypolicy' exact component={PrivacyPolicy} />
        </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
