import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { Icon } from "@iconify/react";
import locationIcon from '@iconify/icons-mdi/map-marker-radius-outline'
import phoneIcon from '@iconify/icons-mdi/phone-outline'
//import emailIcon from '@iconify/icons-mdi/email-multiple-outline'

function Footer() {
  return (
    <div className='footer-container'>
      
      
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div>
            <Link to='/' className='social-logo'>
            <div class='footer-logo'/>
            </Link>
          </div>
        
        <div class='address'>
            
	    <p>Zuwen Bina Sdn. Bhd 200301030665 / 633085-W</p>
            <p><Icon icon={locationIcon}/> 11, Medan Permai Jaya<br />Taman Permai Jaya,<br />14000 Bukit Mertajam, Pulau Pinang, Malaysia.</p>
            <p><Icon icon={phoneIcon}/> +604 5383388</p>           
            
        </div>

          </div>
      </section>
    </div>
  );
}

export default Footer;

//<p><Icon icon={emailIcon}/> sales@zuwenbina.com.my</p> <small class='website-rights'>ZUWEN BINA ©</small>
