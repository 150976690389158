 /* eslint-disable */ 
import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './react-awesome-slider.css';
import { Link } from 'react-router-dom';
import './Header.css';
import './new-project.css';


const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function Slider() {
  return (
              <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
            >
              <div data-src="images/utama_sanctuary_poster.jpg" />
              <div data-src="SlideImage/sg_clubhouse_sd_min.jpg" />
              <div data-src="SlideImage/sg_id_relax_sd_min.jpg" />
              <div data-src="SlideImage/sr_living_sd_min.jpg" />
              <div data-src="SlideImage/sr_building_sd_min.jpg" />
            </AutoplaySlider>
  );
}


/*

             
 
              


*/
