import React from 'react';
import '../../App.css';
import '../Header.css';
import CompletedProjects from '../CompletedProjects';
import FutureProjects from '../FutureProjects';
import Footer from '../Footer';

function Projects() {
  return (
    <>
      <FutureProjects />
      <CompletedProjects />
      <Footer/>
  </>
  );
}

export default Projects;