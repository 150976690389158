import React from 'react';
import '../../App.css';
import './ContactUs.css';
//import GoogleMapReact from 'google-map-react';
import MapSection from '../Map' // import the map here
import ContactSection from '../ContactInfo'


export default function ContactUs() {

    const location = {
        address: 'Zuwen Bina Sdn. Bhd.',
	lat: 5.336687826339787,
	lng: 100.46127387419949,
    }

    return(
        <div className="contact">
            <MapSection location={location} zoomLevel={17} /> {/* include it here */}
            <ContactSection/>
        </div>
    )
}
