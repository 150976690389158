import React from 'react';
import './ProjectCards.css';
import ProjectCards from './ProjectCards';

function CompletedProjects() {
  return (
    <div className='projectcards'>
      <h1 style={{fontFamily : 'Roboto', color: '#224AA8'}}>Completed Projects</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ProjectCards
              src='images/sg1.jpg'
              text='Spectrum Garden (2019)'
              path='/spectrumgarden'
            />
            <ProjectCards
              src='images/sr1.jpg'
              text='Spectrum Residence (2018)'
              path='/spectrumresidence'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CompletedProjects;
