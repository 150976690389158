import React from 'react';
import './ProjectCards.css';
import ProjectCards from './ProjectCards';

function FutureProjects() {
  return (
    <div className='projectcards'>
      <h1 style={{fontFamily : 'Roboto', color: '#224AA8'}}>Upcoming Projects</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ProjectCards
              src='images/utama_sanctuary_condo.jpg'
              text='Utama Sanctuary Bukit Mertajam'
              path=''
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FutureProjects;
