import React from 'react';
import './Company.css';
import Footer from '../Footer';

function Company() {
  return (
    <>
    <body className='body-container'>
      <h1>About Us</h1>
      <br/>
      <div className="text-block2">
	  <p>
          We started from a humble beginning as Peakever Construction Sdn. Bhd. which was then established in August 1993. The company had then covered various construction grounds from residential housing such as apartments, walk-up flat and condomiums to shop houses, light industrial factories as well as shopping complexes.
	  </p>
	  <p>
	  Zuwen Bina Sdn. Bhd. was officially established in March 2008 superceding Peakever Sdn. Bhd to cover the end-to-end from construction to delivering product directly to the customer with at a very affordable price by reducing the intermediaries. 
	  </p>
	  <p>
	  Till date, the company had almost 30 years of experience in construction and development ring with a full fledge veterans to deliver a non-compromised level of quality and comfort yet affordable properties to all walks of life.
	  </p>
      </div>
      <h1>Our Mission and Vision</h1>
      <br/>
      <h2>"We Build A Place You Called Home"</h2>
      <div className="text-block2">
          <p>
          Zuwen Bina envisioned to provide quality and affordable homes to all walks of life with the enjoyment of bustling city lifestyle. We hold our customer's satisfaction to the utmost importance with a peace of mind to focus on building and growing families in a safe and healthy environment.
          </p>
      </div>
    </body>

    <Footer/>
    </>
  );
}

export default Company;
